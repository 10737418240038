// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseURL: "https://api.reachet.org.et/pdts-api/v1/api",
  production: false,
  METABASE_SITE_URL: "https://metabase.reachet.org.et/public/dashboard",
  METABASE_SECRET_KEY:
    "cd98c7566a958700e3bc40120edffdac1475da808868c2d04d6b5f11de55d2de",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
